// ================================================================================================
//  File Name: bootstrap.scss
//  Description: Include bootstrap core from node_modules
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import 'bootstrap-extended/include'; // Bootstrap includes
@import 'bootstrap/scss/bootstrap'; // Bootstrap core
$danger: #E20917;
$primary: #E20917;