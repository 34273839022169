.toast-success {
  background-color: #2B2E42;
  color: #fff;
  padding: 15px 15px;
  border: 0;
}
.toast-error {
  background-color: #2B2E42;
  color: #fff;
  padding: 15px 15px;
  border: 0;
}