@import 'variables/variables';
// Component variable
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// css card item family
body {
  font-family: 'Roboto', 'Montserrat', -apple-system, 'Helvetica Neue',
    sans-serif;
  min-width: 37.5rem;
}

.v-body {
  width: 80%;
  margin: auto;

  h1 {
    margin-right: 15px;
  }

  .grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  }

  .v-card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    .count-item-label {
      font-weight: bold;
    }
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

@keyframes focusArticleAnimation {
  0% {
    background: #e2e2e27e;
  }
  100% {
    background-color: #fff;
  }
}

.updated-row {
  animation: focusArticleAnimation 3s ease-in-out 1s both;
}

// for main menu toggle lock
.modern-nav-toggle {
  padding: 0;
  margin: 1.85rem 0;
}

// ** Horizontal header

.header-navbar {
  .navbar-container {
    padding-left: 0rem;
    padding-right: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

// ** Header logo

.main-menu {
  .navbar-brand {
    .brand-logo {
      display: flex;
      min-width: 270px;
      justify-content: center;
      align-items: center;

      img {
        min-width: 178px;
        // border-right: 2px solid #000000;
      }
    }
  }
}

.brand-logo-not-auth {
  display: flex;
  min-width: 270px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.35rem;

  img {
    justify-content: center;
    align-items: center;
    margin-left: 46px;
    margin-right: 36px;
  }
}

// ** Header app title

.brand-text {
  text-align: center;
  align-items: center;
  color: #000000;
  list-style: none;
  list-style-type: none;
  font-family: Montserrat, sans-serif;
  border-left: 2px solid #000000;
  padding-left: 20px;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
}

.fallback-spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.app-loader {
    height: 100vh;
    flex-direction: column;

    .loading {
      margin-top: 1rem;
    }
  }

  .loading {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .effect-1,
    .effect-2,
    .effect-3 {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-left: 3px solid rgba(224, 12, 26, 1);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .effect-1 {
      position: absolute;
      animation: rotate 1s ease infinite;
    }

    .effect-2 {
      position: absolute;
      animation: rotateOpacity 1s ease infinite 0.1s;
    }

    .effect-3 {
      -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
      animation: rotateOpacity 1s ease infinite 0.2s;
    }

    .loading .effects {
      transition: all 0.3s ease;
    }
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.fallback-logo {
  position: absolute;
  left: calc(50% - 6px);
  top: 50%;
}

.me-05 {
  margin-right: 0.05rem !important;
}

.spinner-border-xs {
  width: 0.7rem;
  height: 0.7rem;
}

.xbadge-absolute {
  position: absolute;
  right: 120px;
  top: 30%;
}

.option-absolute {
  position: absolute;
  top: 30%;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mr-2 {
  margin-right: 1rem !important;
}
.mr-3 {
  margin-right: 2rem !important;
}
.mr-4 {
  margin-right: 5rem !important;
}

.mr-10 {
  margin-right: 30rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mx-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.h7 {
  font-size: 0.9rem !important;
}
.flex-grow-2 {
  flex-grow: 2 !important;
}
.flex-grow-3 {
  flex-grow: 3 !important;
}
.flex-grow-4 {
  flex-grow: 4 !important;
}
.flex-grow-5 {
  flex-grow: 5 !important;
}
.text-action {
  color: #6e6b7b;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
}

.text-sm {
  font-size: 0.875rem !important;
}

.title-black-28 {
  //styleName: Title 28 M;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #1a1a1a;
}
.text-red-18-bold {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #e20917;
}
.title-red-16 {
  color: #e20917;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}
.text-red-14 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
  color: #e20917;
}
.text-grey-14 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
  color: #868793;
}
.text-black-16-bold {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
}
.text-black-20 {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.text-grey-16-italic {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;
}
.text-grey-12 {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #dcdcdc;
}
.btn-red-14 {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #e20917;
  background-color: transparent;
  border: 1px solid #e20917;
  border-radius: 15px;
  padding: 5px 15px;
}

.btn-black-14 {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2b2e42;
  background-color: transparent;
  border: 1px solid #2b2e42;
  border-radius: 15px;
  padding: 5px 15px;
}
.avatar [class*='avatar-status-'] {
  border-radius: 50%;
  border: 0px solid #fff;
}

.avatar [class*='avatar-status-'] {
  width: 65px;
  height: 11px;
  position: absolute;
  top: -0.3em;
}

.dropdown-language .country-flag {
  height: 21px !important;
  width: 18px !important;
}

input {
  outline: none;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px dotted #797979;
  border-radius: 8px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.editor-wrapper {
  border: 1px solid #e1e1e4;
  border-radius: 8px;
  background-color: #f1f1f1;
  .editor-toolbar {
    border-radius: 8px;

    margin: 0;
    border: 0;
    background-color: #f1f1f1;
    //border-bottom: 1px solid #8a8a8a;
  }
  .editor-editor {
    margin: 0;
    border-radius: 8px;
    //margin-left: 5px;
    background-color: white;
  }
}

.me-02 {
  margin-right: 0.02rem !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-90 {
  max-width: 90% !important;
}

.bg-grey {
  background-color: rgba(171, 174, 191, 1) !important;
}

.text-darker {
  color: rgba(43, 46, 66, 1) !important;
}

// ** Task

.card-tasks .tasks-body {
  padding: 0rem 1.2rem 1.2rem 1.2rem !important;
}

.task-warning {
  border-left: 0.4rem solid;
  border-color: rgba(var(--bs-warning-rgb)) !important;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.task-danger {
  border-left: 0.4rem solid;
  border-color: rgba(var(--bs-danger-rgb)) !important;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.task-info {
  border-left: 0.4rem solid;
  border-color: rgba(var(--bs-info-rgb)) !important;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.task-success {
  border-left: 0.4rem solid;
  border-color: rgba(var(--bs-success-rgb)) !important;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.task-secondary {
  border-left: 0.4rem solid;
  border-color: rgba(var(--bs-secondary-rgb)) !important;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}

.task {
  text-align: left;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
  box-shadow: 0 2px 15px 0 rgba(34, 41, 47, 0.05) !important;
  border-radius: 0.357rem;
  border-bottom: 0 solid transparent !important;
  overflow: hidden;
}

.task .card-body {
  padding: 0.42rem 1rem 0.42rem 1rem;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 54px;
  font-size: 1rem;
  color: #6e6b7b;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s, border-radius 0.15s ease;
}

.task-priority .accordion-button {
  position: relative;
  padding: 0.42rem 1rem 0.42rem 1rem;
  display: block;
  width: 100%;
  font-size: 1rem;
  color: #6e6b7b;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s, border-radius 0.15s ease;
}

.task-priority .accordion-button::after {
  float: right;
  position: static;
  margin-top: -25px;
}

.task-priority .accordion-button h6 {
  width: 90%;
}

.task-priority .accordion-button p {
  width: 90%;
  font-weight: normal;
}

.task-priority .task {
  min-height: 50px;
}

// ** Card

.card-rdv-dark {
  box-shadow: 6px 5px 15px -4px rgba(255, 41, 47, 0.5) !important;
  border-radius: 0.357rem;
  border-bottom: 0 solid transparent !important;
}

.card-rdv {
  box-shadow: 6px 5px 15px -4px rgba(0, 41, 47, 0.5) !important;
  border-radius: 0.357rem;
  border-bottom: 0 solid transparent !important;
}

.w-150 {
  max-width: 150px;
}

.w-200 {
  max-width: 200px;
}

.w-8 {
  width: 8rem;
}

.w-30 {
  width: 30%;
}

.w-300 {
  max-width: 300px;
}

.dark-layout .breadcrumb .breadcrumb-item a {
  color: #fff !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  background-color: transparent !important;
}

.dark-layout {
  .task {
    margin-top: 0.71rem;
    margin-bottom: 0;
    box-shadow: 0 2px 15px 0 rgba(34, 41, 47, 0.05) !important;
    border-radius: 0.357rem;
    border-bottom: 0 solid transparent !important;
  }
  .task .card-body {
    padding: 0.42rem 1rem 0.42rem 1rem;
    background-color: #071127;
    border: 0;
    position: relative;
    width: 100%;
    font-size: 1rem;
    color: #fff;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      background 0s, border 0s, border-radius 0.15s ease;
  }

  .task-priority .accordion-button {
    position: relative;
    padding: 0.42rem 1rem 0.42rem 1rem;
    display: block;
    width: 100%;
    font-size: 1rem;
    color: #fff;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      background 0s, border 0s, border-radius 0.15s ease;
  }

  .task-priority .accordion-button::after {
    float: right;
    position: static;
    margin-top: -25px;
  }

  .task-priority .accordion-button h6 {
    width: 90%;
  }

  .task-priority .accordion-button p {
    width: 90%;
    font-weight: normal;
  }

  .task-priority .task {
    min-height: 50px;
  }

  .form-control {
    &:focus {
      border: 1px solid white !important;
    }
  }

  .input-label {
    .form-floating {
      color: #fff !important;
    }
    &:focus {
      color: #fff !important;
    }
    color: #fff !important;
  }
}

.badge-custom {
  display: flex;
  align-items: center;
}

.badge-custom-scroll {
  width: 60%;
  margin: 0 auto;
  overflow-x: scroll;
  display: flex;

  &::-webkit-scrollbar {
    width: 20px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}
.text-action {
  color: '#6e6b7b';
  font-weight: 400;
  font-size: 14;
  text-transform: 'none';
}

.title-black-28 {
  //styleName: Title 28 M;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #000000;
}

.text-red-18-bold {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #e20917;
}

.title-red-16 {
  color: #e20917;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.text-red-14 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
  color: #e20917;
}

.text-grey-14 {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
  color: #868793;
}

.text-black-16-bold {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
}

.text-black-20 {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.text-grey-16-italic {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;
}

.text-grey-12 {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #dcdcdc;
}

.btn-red-14 {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #e20917;
  background-color: transparent;
  border: 1px solid #e20917;
  border-radius: 15px;
  padding: 5px 15px;
}

.btn-black-14 {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2b2e42;
  background-color: transparent;
  border: 1px solid #2b2e42;
  border-radius: 15px;
  padding: 5px 15px;
}
.avatar [class*='avatar-status-'] {
  border-radius: 50%;
  border: 0px solid #fff;
}

.avatar [class*='avatar-status-'] {
  width: 11px;
  height: 11px;
  position: absolute;
  top: -0.3em;
}

.gap-x-small {
  gap: 1rem 5rem;
}

.dropdown-language .country-flag {
  height: 21px !important;
  width: 18px !important;
}

input[type='file'] {
  display: none;
}

.min-w-content {
  min-width: fit-content;
}

.min-card {
  min-width: 300px;
}

.w-250 {
  width: 273px;
}

.h-12 {
  height: 30px;
}

.w-12 {
  width: 30px;
}

.max-w-256 {
  max-width: 256px;
}

.max-h-256 {
  max-height: 256px;
}

.max-h-500 {
  max-height: 500px;
}

.no-qr-code {
  width: 220px;
  height: 220px;
}

.shadow-flags {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.store-icons {
  width: 200px;
  height: 70px;
}

.truncate-cell {
  position: relative;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-upload {
  border: 1px dotted #797979;
  border-radius: 8px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.editor-wrapper {
  border: 1px solid #e1e1e4;
  border-radius: 8px;
  background-color: #f1f1f1;

  .editor-toolbar {
    border-radius: 8px;

    margin: 0;
    border: 0;
    background-color: #f1f1f1;
    //border-bottom: 1px solid #8a8a8a;
  }

  .editor-editor {
    margin: 0;
    border-radius: 8px;

    //margin-left: 5px;
    background-color: white;
  }
}

.over-flow {
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
}

.absolute-client {
  position: absolute;
  top: 230px;
  left: 0;
  z-index: 1;
  text-decoration: underline;
}

.new-contact {
  position: absolute;
  top: 0px;
  left: 200px;
  z-index: 1;
  text-decoration: underline;
}

.dx-item-content {
  padding: 0px !important;
  margin: 0px !important;
  height: 100%;

  .over {
    opacity: 0.3;
  }
}

.dx-calendar-view-month {
  height: 320px;
}

.dx-list-item-content {
  padding: 0px !important;
  margin: 0px !important;
  height: 200px;
}

.dx-scheduler-all-day-appointment {
  padding-left: 5px;
}

.text-shadow-scheduler {
  text-shadow: 1px 1px 2px #191b1e;
}

.text-shadow-scheduler {
  text-shadow: 1px 1px 2px #191b1e;
}

.zindex-superior {
  z-index: 9999;
}

.zindex-inferior {
  z-index: 1;
}

.documents-card {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.appointment-lines {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 15002;
  height: 100%;
  width: 50px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-100 {
  min-height: 110px !important;
}

.popup-title {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.popup-text {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cheduler-all-day-appointment {
  width: 100%;
  height: 100%;
  background-color: red;
}
.badge-custom {
  display: flex;
  align-items: center;
}

.badge-custom-scroll {
  width: 60%;
  margin: 0 auto;
  overflow-x: scroll;
  display: flex;

  &::-webkit-scrollbar {
    width: 20px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }
}

//settings
.param-general .p-2 {
  padding: 1rem 1.5rem 1rem 1.5rem !important;
}

// Modals

.modal {
  // Modal Header

  .modal-header {
    // close button
    .btn-close {
      box-shadow: none;
      transform: translate(0, 0);
      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        transform: translate(0, 0);
        box-shadow: 0 5px 20px 0 rgba(#000, 0.1);
        transition: all 0.23s ease 0.1s;
      }
    }
  }

  .suppress-icon {
    position: absolute;
    right: -200px;
  }

  .modal-fullscreen {
    .modal-header {
      .btn-close {
        transform: translate(0, 0);
      }
    }
  }
}

.modal-slide-in {
  .btn-close {
    transform: translate(0, -50%);
  }
}

.mt-025 {
  margin-top: 0.25rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.px-05 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.py-1-5 {
  padding: 1.5rem 0;
}
.mw-170 {
  min-width: 170px;
}

.m-w-50 {
  min-width: 50%;
}

.pointer {
  cursor: pointer;
}

.w-45 {
  width: 45%;
}

.w-15 {
  width: 15%;
}

.w-60 {
  width: 60%;
}

.p-05 {
  padding: 0.5rem;
}

.p-02 {
  padding: 0.2rem;
}

.preview-img {
  height: 65px;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  border: 1px rgb(230, 225, 225) solid;
  border-radius: 8px;
  padding: 0.5rem 0;

  .overflow-ellispsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 10%;
    margin: 0 10px 0 0;
  }
}

.preview-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: default;
  span {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    background-color: lightgray;
    width: 40px;
    height: 40px;
    border-radius: 10%;
    margin: 0 10px 0 0;
  }
}

.avat {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-info {
  width: 100px;
  height: 100px;
}

//DARK LAYOUT

.dark-layout {
  .css-6oezm2-control {
    background-color: transparent !important;
    color: #fff;
  }
  .css-6j8wv5-Input {
    background-color: transparent !important;
    color: #fff;
  }
  .css-43a5xv-control,
  .css-1s2u09g-control {
    background-color: transparent !important;
    color: #fff;
  }
}

//DATAGRID DARK LAYOUT

.dark-layout .MuiBox-root {
  color: #b4b7bd;
}

.dark-layout .MuiDataGrid-booleanCell {
  color: #b4b7bd !important;
}

.dark-layout .MuiDataGrid-columnHeaderTitle {
  color: #fff !important;
}

.dark-layout .MuiDataGridPro-panelFooter {
  color: #fff !important;
}

.dark-layout .columnHeaderCheckbox {
  color: #fff !important;
}

.dark-layout .MuiDataGrid-toolbarContainer {
  color: #fff !important;
}

.dark-layout .MuiDataGrid-cellContent {
  color: #fff !important;
}

.dark-layout .MuiDataGrid-cell--withRenderer {
  color: #fff !important;
}

.dark-layout .MuiButton-textPrimary {
  background-color: #2b2e41;
  color: #fff !important;
}

.dark-layout .MuiInput-underline {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.dark-layout .MuiDataGrid-paper {
  background-color: #2b2e41;
}

.dark-layout .MuiButton-text {
  color: #fff !important;
}

.dark-layout .MuiButton-startIcon {
  color: #b4b7bd !important;
}

.dark-layout .MuiBox-root .textPrimary {
  color: #b4b7bd !important;
}

.dark-layout .MuiDataGrid-row--editing .MuiDataGrid-cell {
  box-shadow: none;
  background-color: #161d31;
  color: #fff !important;
}

.dark-layout .MuiInputBase-input {
  color: #fff !important;
}

.dark-layout .MuiSvgIcon-root {
  color: #fff !important;
}

.dark-layout .MuiDataGrid-toolbarContainer .btn {
  color: #fff !important;
}

.dark-layout .Mui-selected {
  background-color: #b1b1b198;
}

.dark-layout .MuiCircularProgress-svg {
  color: #fff;
}

.dark-layout .Mui-focused {
  color: #fff !important;
}

.dark-layout .Mui-disabled {
  color: #81838e !important;
  -webkit-text-fill-color: #81838e !important;
}

.dark-layout .MuiDataGrid-overlay {
  background-color: #2b2e42;
  color: #fff !important;
}

.dark-layout button {
  color: #fff !important;
}

//DATAGRID GLOBAL

.MuiPaper-root ul {
  padding-left: 0px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-panel {
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  right: 0;
}

.MuiDataGrid-autoHeight {
  position: relative;
}

//datagrid pro filters

.MuiDataGrid-row {
  cursor: pointer;
}

.btn-bottom-abs {
  button {
    position: fixed;
    bottom: -300px;
    right: 20px;
  }
}

// Option button icon

.icon-md {
  width: 25px;
  height: 30px;
}

.option-btn-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgb(240, 240, 240);
  }
}

// animation transition
.tab-animation {
  transition: all 2s ease-in-out;
}

.input-swall {
  box-shadow: none;
  border: none;
  &:focus {
    box-shadow: none;
    border: none;
  }
  &:hover {
    box-shadow: none;
    border: none;
  }
  &:active {
    box-shadow: none;
    border: none;
  }
}

.custom-settings-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.input-padding {
  padding: 0.571rem 1rem;
}

.mt-0-5 {
  margin-top: 0.5rem;
}

.p-010 {
  padding: 0.1rem;
}

.p-020 {
  padding: 0.2rem;
}

.fit-content {
  width: fit-content;
}

.border-radius-50 {
  border-radius: 50%;
}

.max-height-500 {
  max-height: 500px;
}

.max-h-500-overflow {
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.h-200 {
  height: 200px;
}
.border-dot {
  border: 1px dashed black;
}

.radio-custom:checked {
  background-color: red;
  border: none;
}

.background-icons {
  padding: 4px 6px;
  background-color: #161d31;
  border-radius: 5px;
}

.admin-title {
  font-size: 0.9rem;
  font-weight: 400;
  color: rgba(68, 65, 65, 0.8);
  font-style: italic;
}

//notification
.notification-date {
  font-size: smaller;
  color: #b9b9c3;
}

.bg-header {
  background-color: #fafafa;
}

.bg-grey {
  background-color: #d3d0d0;
}

.bg-navy {
  background-color: #2b2e42;
}

.gap-0-5 {
  gap: 0.5rem;
}

.p-025 {
  padding: 0.25rem;
}

.swall-input {
  font-size: 1rem;
  width: 80%;
  margin: 10px 0;
  outline: none;
  &:focus {
    outline: none;
    box-shadow: none !important;
    border: 1px solid lightgray;
  }
  &:active {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
}

.btn-notifications {
  background-color: #2b2e41;
  color: #fff;
  border-radius: 15px;
  border: 0;
  padding: 0.7rem 1.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.my-validation-message::before {
  display: none;
}

.formFeedBack {
  font-size: 0.857rem;
  color: #ea5455;
}

.italic {
  font-style: italic;
}

.bg-main {
  background-color: #eeeeee;
}

.border-bottom-custom {
  border-bottom: 2px solid #e1e1e4;
  &.active {
    border-bottom: 3px solid #e20917;
  }
}

.h-30 {
  height: 30px;
}

.h-120 {
  min-height: 120px !important;
  max-height: 240px !important;
}

.h-300 {
  min-height: 300px !important;
  max-height: 600px;
}

.inputCustom-transparent {
  //input without border and background until focus

  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
    border: none;
    background-color: transparent;
  }
}

.add-contact {
  position: absolute;
  left: 200px;
}

.cancel-icon {
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  padding: 2px;
  width: 10px;
  height: 10px;
}

.w-85 {
  width: 85% !important;
}

.custom-label {
  opacity: 0.9;
  font-size: 12px;
}

.transition-button {
  transition: all 0.3s ease-in-out;
}

.break-word {
  word-break: break-all;
  white-space: normal;
}

.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1440px) {
  .w-75 {
    width: 90% !important;
  }
}

@media (max-width: 1440px) {
  .w-85 {
    width: 95% !important;
  }
}

//MAP
.input-legend {
  font-size: 0.8rem;
}

.list {
  font-family: sans-serif;
  text-align: center;
}

.c-office-overview__map {
  height: 70vh;
  width: 100%;
}

.c-office-overview__map-selected-info__close {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
}

.select__control .select__multi-value .select__multi-value__label {
  color: #071127;
}

.react-select__control {
  // border: 1px solid #d8d6de;
  outline: none;
}

.react-select__value-container {
  cursor: pointer;
}

.react-select__value-container--has-value {
  color: #6e6b7b;
}

/* .react-select__indicators {
}
 */
.react-select__menu {
  z-index: 9999;
  background-color: white;
}

/*
.react-select__menu-list {
  .dark-layout {
  }
}

.react-select__option {
  .dark-layout {
  }
} */

.dark-layout .select__control .select__multi-value .select__multi-value__label {
  color: #ffffff !important;
}

.dark-layout .select__control .select__multi-value__remove svg {
  fill: #ffffff !important;
}

.c-customer-overview__map {
  height: 70vh;
  width: 100%;
}

.c-customer-overview__map button.gm-ui-hover-effect {
  visibility: hidden;
}

.c-customer-overview__map .bg-light-dark {
  background-color: #000 !important;
}

.adr p {
  line-height: 1.2rem;
}

.c-customer-overview__map .card-developer-meetup {
  margin-bottom: 0 !important;
}

.c-customer-overview__map .card-developer-meetup .list-group-item {
  min-width: 482px;
}

.c-customer-overview__map
  .card-developer-meetup
  .list-group-item
  .card
  .card-body {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.c-customer-overview__map .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.c-customer-overview__map .list .card {
  margin-top: 1rem;
  margin-bottom: 0;
}

.c-customer-overview__map .coord .avatar.avatar-lg .avatar-content {
  width: 50px;
  height: 100%;
  font-size: 1.714rem;
}

.c-customer-overview__map .card-customer-title {
  align-items: normal !important;
  justify-content: left !important;
}

.c-customer-overview__map .card-header {
  padding-bottom: 0 !important;
}

.c-customer-overview__map .card-header .content-header-left {
  display: inline-flex !important;
}

.c-customer-overview__map .card-header .dropdown-toggle-split {
  padding-top: 0;
  padding-bottom: 0;
}

.map-header .waves-effect {
  position: relative;
  overflow: hidden;
}

.exclamation {
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: white;
    z-index: -5;
    position: absolute;
  }
  position: absolute;
  right: 0;
  top: -10px;
}

.c-customer-overview__map .gm-style .gm-style-iw-c {
  min-width: 548px !important;
  min-height: 400px !important;
}

.timeline-point-quote {
  border: 1px solid #7367f0 !important;
  background-color: #7367f0 !important;
  svg {
    color: white !important;
  }
  &::before {
    content: '';
    border-radius: 50%;
    background: #7367f01f;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    display: block;
    position: absolute;
    top: -0.6rem;
  }
}

.earnings-card {
  transition: all 0.3s;
  &:hover {
    box-shadow: 1px 4px 24px 0 rgba(34, 41, 47, 0.301);
  }
}

.timeline-point-task {
  border: 1px solid #ff9f43 !important;
  background-color: #ff9f43 !important;
  svg {
    color: white !important;
  }
  &::before {
    content: '';
    border-radius: 50%;
    background: #ff9f431f;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    display: block;
    position: absolute;
    top: -0.6rem;
  }
}

.bg-white {
  background-color: #fff !important;
}

//Search navBar
.dark-layout
  .autocomplete-container
  .suggestions-list
  .suggestion-item.suggestion-title-wrapper
  h6,
.dark-layout
  .autocomplete-container
  .suggestions-list
  .suggestion-item.suggestion-title-wrapper
  .h6 {
  color: #fff;
}

.dark-layout
  .autocomplete-container
  .suggestions-list
  .suggestion-item
  .item-info {
  color: #e0e0e0 !important;
}

.dark-layout .autocomplete-container .text-muted {
  color: #c7c7c7 !important;
}

.itemFamilyCard:hover {
  background-color: #dcdcdc !important;
}

.PhoneInputInput {
  border: 0 !important;
}

.total-stat {
  padding: 5px;
  background-color: #2b2e42;
  color: white;
  font-weight: bold;
}

.objective-realized-before::before {
  content: '•';
  font-size: 30px;
  vertical-align: middle;
  margin-right: 5px;
  color: #ff0000;
}

.objective-before::before {
  content: '•';
  font-size: 30px;
  vertical-align: middle;
  margin-right: 5px;
  color: #d9d9d9;
}

.inherit {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.right-1 {
  right: 2rem;
}

.select-hover:hover {
  background-color: #2684ff33 !important;
}

.no-scrollbar {
  //no scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

.status-avatar {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}

.responsive-line {
  width: 50%;
}

.reset-button {
  background-color: transparent;
  border: 1px solid #ced4da;
  width: 12rem;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: 0.2s ease-in-out;
  &:hover {
    border: 1px solid #ced4da;
    box-shadow: 0 1px 6px rgba(34, 41, 47, 0.166);
    color: #495057;
  }
}

.height-500-scroll {
  min-height: 100px;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 5px;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e4e4;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d4dada;
  }
}

th,
td {
  padding: 10px !important;
  margin: 0 !important;
}

.task-title {
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1440px) {
  .responsive-stats {
    background-color: rgb(246, 246, 246);
    margin: 10px 0;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .responsive-line {
    width: 100%;
  }
}

// Vertical navigation sidebar items
.navbar-horizontal {
  background: #c41717;
}

.navbar-nav {
  .active {
    &.nav-link {
      background-color: #000000;
    }
  }
}

.vertical-layout .main-menu {
  background-color: #191919;
  .navigation {
    background-color: #191919;
    a.active {
      margin: 0px;
      border-radius: 0px;
      box-shadow: none;
      z-index: 1;
      border-left: 0.4rem solid #e10600;
      background: white;

      font-weight: 400;
      span {
        color: black;
      }

      svg {
        color: #e10600;
      }
    }
  }
}

.arrowsidebar {
  position: absolute;
  right: -105px;
  top: 670px;
  z-index: 2;
}

@media (max-width: 1090px) {
  .statistics-chart-card {
    max-width: 700px;
  }
}

@media (min-width: 1090px) and (max-width: 1100px) {
  .statistics-chart-card {
    min-width: 950px;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .statistics-chart-card {
    min-width: 935px;
  }
}

@media (min-width: 1200px) and (max-width: 1345px) {
  .statistics-chart-card {
    max-width: 700px;
  }
}

@media (min-width: 1345px) {
  .statistics-chart-card {
    min-width: 990px;
  }
}

.dx-overlay-content {
  overflow: visible;
}

.calendar-create-event-field {
  text-align: right;
}

.calendar-create-event-field > div {
  padding-right: 2rem;
}
/* APPLICATION MOBIL */
.page-title {
  margin: 0 auto;
  text-align: left;
  padding: 0.5rem 0;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  color: rgb(80 80 80);
  font-weight: bold !important;
}
.page-title::first-letter {
  text-transform: uppercase;
}
.margin-unset {
  margin: unset;
}
.text-capitalize {
  text-transform: capitalize;
}
.app-mobile {
  max-width: 84%;
  margin: 0 auto;
  text-align: left;
  padding: 0.5rem 0;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  color: rgb(80 80 80);
  font-weight: bold !important;
}
.mobil-download {
  min-height: calc(100vh - 8rem);
  padding: 1.5rem 0.5rem 1.5rem 4rem;
  max-width: 84%;
  margin: 0 auto;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10vw !important;
  }

  &__phone-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &-arrow-right {
      position: absolute;
      right: -81px;
      top: 81px;
      z-index: 10;
      width: 140px;
      height: 178px;
    }

    &-arrow-left {
      position: absolute;
      left: -47px;
      bottom: 81px;
      z-index: 10;
      width: 94px;
      height: 116px;
    }

    &-phone {
      min-width: 200px;
      width: 253px;
      height: 573px;
      position: relative;
      z-index: 20;
    }
  }

  &__content {
    padding: 1.5rem 2.5rem;
    border: 1px solid #f2f2f2;
    max-width: 700px;
    width: 100%;
    border-radius: 0.5rem;
  }

  &__text-group {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    &-title {
      font-size: 24px;
      font-family: 'Lato', sans-serif;
      text-align: center;
      color: #2f2f2f;
      font-weight: bold;
    }

    &-description {
      text-align: center;
      font-family: 'Lato', sans-serif;
      font-size: 20px;
      color: #2f2f2f;
    }

    &-note {
      text-align: center;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      color: #8d8d8d;
    }
  }

  &__qr-code-group {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    gap: 1rem;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;

      & img {
        width: 100px;
      }

      & .qr-code-svg {
        width: 70%;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 1230px) {
  .mobil-download {
    &__wrapper {
      flex-direction: column;
      gap: 10vw;
    }
  }
}
/* SKELETON APP MOBILE */
.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1.5rem;
  margin-top: 10rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 4rem;
  }

  &__card {
    box-shadow: var(--card-shadow);
    border-radius: 0.5rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__card-wide {
    width: 675px;
    box-shadow: var(--card-shadow);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &-skeleton {
      width: 100%;
      height: 380px;
      border-radius: 0.5rem;
      max-width: 675px;
    }

    &-content {
      position: absolute;
      display: flex;
      flex-direction: column;
      margin: 1rem;
      width: 500px;

      &-section {
        height: 3rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
          width: 18rem;
          height: 100%;
          background-color: #292828;
        }

        &__text-full {
          width: 100%;
          height: 100%;
          background-color: #292828;
        }

        &__text-wide {
          width: 24rem;
          height: 100%;
          background-color: #292828;
        }
      }

      &-icons {
        display: flex;
        justify-content: center;
        gap: 5rem;

        &__icon-container {
          margin-bottom: 0.75rem;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__text {
            width: 6rem;
            height: 3rem;
            background-color: #292828;
            border-radius: 0.5rem;
          }

          &__icon {
            width: 6rem;
            height: 6rem;
            background-color: #292828;
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
