.swal2-popup-custom {
  .swal2-actions {
    .swal2-confirm {
      color: #E20917;
      background-color: transparent;
    }
    .swal2-cancel {
      color: #868793;
      background-color: transparent;
    }
  }
  .swal2-select{
    margin: 0;
  }
}
