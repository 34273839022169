.breadcrumb-chevron-custom {
  margin: 1.5rem 0;
  ol {
    li {
      margin: 0 5px;
      a {
        //styleName: Body 2 reg;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #000000;
      }
      span {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        color: black;
      }
    }
  }
}
